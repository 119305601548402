import React from "react";
import Submenu from "../../components/submenu/Submenu";

import regularDonutsData from "../../data/donuts/regularDonuts.json";
import fancyDonutsData from "../../data/donuts/fancyDonuts.json";
import favoriteDonutsData from "../../data/donuts/favoriteDonuts.json";
import bakedGoodsData from "../../data/donuts/bakedGoods.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Donuts = () => {
  return (
    <div className="container">
      <div className="top__imgContainer">
      <LazyLoadImage effect="opacity" src="images/shop/donutMenu.JPG" alt="Donuts Menu" className="" width="100%" height="100%"/>
      </div>

      <h3 className="container__divide centered">DONUTS MENU</h3>

      <div className="container__col--flex marginTopSm">
        <div className="container--flex">
          <div className="centered container__col--flex padded">
            <div className="col__text container--flex centered width-eighty">
              <h3>REGULAR DOZEN $15.00</h3>

              <h3>REGULAR HALF DOZEN $8.00</h3>

              <p className="textColorBlue">Regular donuts include: </p>
              <ul className="centered">
                <div>
                  <li>- Raised</li>
                  <li>- Old Fashioned</li>
                  <li>- Cakes<br /><br /></li>

                </div>
              </ul>


              <p className="textColorBlue">Swap any regular donut for fancy:</p>
              <ul className="centered">
                <div>
                  <li>- Bar Filled: $1.25 more</li>
                  <li>- Cinnamon roll: $1.25 more</li>
                  <li>- Apple Fritter: $1.25 more</li>
                  <li>
                    - Bear Claw: $1.25 more
                    <br />
                    <br />
                  </li>
                  <li>- French: $0.50 more</li>
                  <li>- Buttermilk: $0.50 more</li>
                  <li>- Bar: $0.50 more</li>
                  <li>- Twist: $0.50 more</li>
                  <li>
                    - Jelly Filled: $0.50 more
                    <br />
                    <br />
                  </li>
                </div>
              </ul>

              <h2>FOR 3+ DOZEN,</h2>
              <h2>PLEASE CALL 24HRS IN ADVANCE</h2>
            </div>
          </div>

          <div className="container__col--flex centered">
            <LazyLoadImage effect="opacity"
              src="images/shop/donutFront.JPG"
              alt="Dozen Donuts"
              className="img--full"
            />
          </div>
        </div>
      </div>

      <div className="container__col--flex marginTopSm">
        <div className="container--flex .item__container">
          <div className="centered container__col--flex padded">
            <div style={{lineHeight: '2rem'}} className="col__text container--flex centered width-eighty">
              <p>DOZEN DONUT HOLES $2.75</p>
              <p>HALF DOZEN DONUT HOLES $1.50</p>
            </div>
          </div>

          <div className="container__col--flex centered">
            <LazyLoadImage effect="opacity"
              src="images/donuts/donutHoles.JPG"
              alt="Donut Holes"
              className="img--full"
            />
          </div>
        </div>
      </div>

      <Submenu header={"REGULAR DONUTS"} data={regularDonutsData} />
      <Submenu header={"FANCY DONUTS"} data={fancyDonutsData} />
      <Submenu header={"FAVORITE DONUTS"} data={favoriteDonutsData} />
      <Submenu header={"BAKED GOODS"} data={bakedGoodsData} />
    </div>
  );
};

export default Donuts;
