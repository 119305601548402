import React from "react";

import { MdLocationPin } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Contact = () => {
  return (
    <div className="container">
      <div className="top__imgContainer">
        <LazyLoadImage effect="opacity"
          src="images/shop/contactHeader.JPG"
          alt="Shop front"
          className=""
          width="100%" height="100%"
        />
      </div>

      <h3 className="container__divide centered ">CONTACT</h3>

      <div style={{marginBottom: '10%'}}className="container__col--flex marginTopSm">
        <div className="container--flex .item__container">
          <div className="centered container__col--flex padded">
            <div className="col__text container--flex centered width-eighty">
              <h2>VISIT US</h2>
              <a
                href="https://www.google.com/maps/place/193+Welburn+Ave,+Gilroy,+CA+95020/@37.0198047,-121.5787446,17z/data=!3m1!4b1!4m5!3m4!1s0x8091e3d1823116b7:0x4bc8594401a27cf2!8m2!3d37.0198047!4d-121.5765559"
                target="_blank"
                rel="noreferrer"
              >
                <MdLocationPin />
                193 Welburn Ave, <br />
                Gilroy, CA 95020
              </a>
              <a href="tel:+14088480859" target="_blank" rel="noreferrer">
                <AiFillPhone />
                (408) 848-0859
              </a>
            </div>
          </div>

          <div className="container__col--flex centered">
            <a href="https://www.google.com/maps/place/193+Welburn+Ave,+Gilroy,+CA+95020/@37.0198047,-121.5787446,17z/data=!3m1!4b1!4m5!3m4!1s0x8091e3d1823116b7:0x4bc8594401a27cf2!8m2!3d37.0198047!4d-121.5765559">
              <LazyLoadImage effect="opacity"src="images/shop/map.png" alt="Map to restaurant" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
