import React from "react";
import "./notFound.css";

const NotFound = () => {
  return (
    <div className="notFound bg-contentBox container--flex">
      <p className="container__col--flex centered padded bg-dom bg-contentBox container--flex ">404. Requested url page not found</p>
    </div>
  );
};

export default NotFound;
