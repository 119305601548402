import React from "react";
import "./header.css";
import { GiDonut } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const Header = (props) => {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <header className="header__container container">
      <div className="header__title">
        <NavLink to="/">
          SUPER DONUT <GiDonut />{" "}
        </NavLink>
      </div>

      <div className="header__toggleButton" onClick={toggleClass}>
        <div className={isActive ? "bar1 change" : "bar1"}></div>
        <div className={isActive ? "bar2 change" : "bar2"}></div>
        <div className={isActive ? "bar3 change" : "bar3"}></div>
      </div>

      <div className={isActive ? "header__nav active" : "header__nav"}>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/donuts">Donuts</NavLink>
          </li>
          <li>
            <NavLink to="/sandwiches">Sandwiches</NavLink>
          </li>
          <li>
            <NavLink to="/beverages">Beverages</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
