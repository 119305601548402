import React from "react";
import Submenu from "../../components/submenu/Submenu";
import "./beverages.css";

import hotData from "../../data/beverages/hot.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Beverages = () => {
  return (
    <div className="container">
      <div className="top__imgContainer">
        <LazyLoadImage effect="opacity"
          src="images/beverages/coffee.JPG"
          alt="Beverages Front"
          className=""
          width="100%" height="100%"
        />
      </div>

      <h3 className="container__divide centered">BEVERAGES MENU</h3>

      <div className="container__col--flex marginTopSm">
        <div className="container--flex .item__container">
          <div className="centered container__col--flex padded">
            <div className="col__text container--flex centered width-eighty">
            <h2>COFFEE ALWAYS FRESH</h2>
              <p className="textColorBlue">Freely add any to your coffee:</p>

              <ul className="centered">
                <div>
                  <li>- Milk </li>
                  <li>- French Vanilla Creamer </li>
                  <li>- Hazelnut Creamer </li>
                  <li>
                    - Powdered Creamer <br /> <br />{" "}
                  </li>
                  <li>- Sugar </li>
                  <li>- Splenda </li>
                  <li>- Sweet 'N Low </li>
                </div>
              </ul>
            </div>
          </div>

          <div className="container__col--flex centered">
            <LazyLoadImage effect="opacity"
              src="images/beverages/coffee2.JPG"
              alt="About"
              className="img--full"
            />
          </div>
        </div>
      </div>

      <Submenu header={"HOT BEVERAGES"} data={hotData} />

      <h3 className="menu__divide centered">COLD DRINKS</h3>

      <div className="flexCol">
        <div className="container__col--flex centered ">
          <LazyLoadImage effect="opacity"
            src="images/beverages/drinks.JPG"
            alt="Dozen Donuts"
            className="img--full"
          />
        </div>

        <div className="centered padded container__col--flex col__text">
          <ul>
            <li>- Water Bottle $1.00</li>
            <li>- Capri-Sun $1.00</li>
            <li>- Sunny D Light $1.75</li>
            <li>- Kern $1.75</li>
            <li>- Nesquick $2.50</li>
            <li>- Milk $2.50</li>
            <li>- Apple Juice $2.50</li>
            <li>- Snapple $2.50</li>
            <li>- Iced Tea $2.50</li>
            <li>- Canned Soda $1.25</li>
            <li>- Bottled Soda $2.50</li>
            <li>- Energy Drink $2.75</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Beverages;
