import React from "react";
import "./footer.css";
import { MdLocationPin } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { FaCcVisa, FaCcMastercard, FaCcApplePay } from "react-icons/fa";

class MobileFooter extends React.Component {
  render() {
    return (
      <footer className="mobileFooter__container container">
        <div className="footer__col footer__contact">
          <a
            href="https://www.google.com/maps/place/193+Welburn+Ave,+Gilroy,+CA+95020/@37.0198047,-121.5787446,17z/data=!3m1!4b1!4m5!3m4!1s0x8091e3d1823116b7:0x4bc8594401a27cf2!8m2!3d37.0198047!4d-121.5765559"
            target="_blank"
            rel="noreferrer"
          >
            <MdLocationPin />
          </a>
        </div>

        <div className="footer__col footer__contact">
          <a href="tel:+14088480859" target="_blank" rel="noreferrer">
            <AiFillPhone />
          </a>
        </div>

        <div className="footer__col footer__hours">
          <p>4:00AM - 2:00PM </p>
        </div>

        <div className="footer__col footer__pay">
          <p>
            <FaCcVisa /> <FaCcMastercard /> <FaCcApplePay />
          </p>
        </div>

      </footer>
    );
  }
}

export default MobileFooter;
