import React from "react";
import Item from "../item/Item";

const Submenu = ({header, data}) => {
  return (
    <div>
      <h3 className="menu__divide centered">{header}</h3>

      <div className="container--flex">
        {data.map((donutDetail, index) => {
          return (
            <Item
              key={index}
              title={donutDetail.title}
              price={donutDetail.price}
              image={donutDetail.image}
              variations={donutDetail.variations}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Submenu;
