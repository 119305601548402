import React from "react";
import "./item.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Item = ({ title, price, image, variations }) => {
  let priceDisplay;
  if (price) priceDisplay = "$" + price.toFixed(2);
  else priceDisplay = "";

  return (
    <div className="container--flex item__container col__text">
      <div className="container__col--flex centered item__text">
        <h2>{title}</h2>
        <p>
          {priceDisplay}
          <br />
          <br />
        </p>
        <ul>
          {variations.map((variation, index) => {
            if (variation === "")
              return <br/>
              
            return (
              <li key={index}>
                - {variation}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="container__col--flex">
        <LazyLoadImage effect="opacity" src={image} alt={title} className="item__img" />
      </div>

    </div>
  );
};

export default Item;
