import React from "react";
import Submenu from "../../components/submenu/Submenu";

import breakfastCroissantsData from "../../data/sandwiches/breakfastCroissants.json";
import breakfastBagelsData from "../../data/sandwiches/breakfastBagels.json";
import croissantSandwichesData from "../../data/sandwiches/croissantSandwiches.json";
import bagelSandwichesData from "../../data/sandwiches/bagelSandwiches.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { AiFillPhone } from "react-icons/ai";

const Sandwiches = () => {
  return (
    <div className="container">
      <div className="top__imgContainer">
        <LazyLoadImage effect="opacity"
          src="images/shop/sandwichMenu.jpg"
          alt="Sandwich Menu"
          className=""
          width="100%" height="100%"
        />
      </div>

      <h3 className="container__divide centered">SANDWICHES MENU</h3>

      <div className="container__col--flex marginTopSm">
        <div className="container--flex .item__container">
          <div className="centered container__col--flex padded">
            <div className="col__text container--flex centered width-eighty">
              <h2>CALL IN ADVANCE TO GRAB AND GO</h2>
              <p>
                <a href="tel:+14088480859">
                  <AiFillPhone />
                  (408) 848-0859
                </a>
                <br /> <br />
                Breakfast and Lunch served all day <br />
                Add meat/avocado $1.00
              </p>
              <p className="textColorBlue">All lunch sandwches include:</p>

              <ul className="centered">
                <div>
                  <li>- Mayonnaise</li>
                  <li>- Cheese</li>
                  <li>- Lettuce</li>
                  <li>- Tomato</li>
                  <li>- Pickle</li>
                  <li>- Onion</li>
                  <li>- Avocado</li>
                </div>
              </ul>
            </div>
          </div>

          <div className="container__col--flex centered">
            <LazyLoadImage effect="opacity"
              src="images/sandwiches/sandwich8.JPG"
              alt="Inside Sandwich"
              className="img--full"
            />
          </div>
        </div>
      </div>

      <Submenu header={"BREAKFAST CROISSANTS"} data={breakfastCroissantsData} />
      <Submenu header={"BREAKFAST BAGELS"} data={breakfastBagelsData} />
      <Submenu header={"CROISSANT SANDWICHES"} data={croissantSandwichesData} />
      <Submenu header={"BAGEL SANDWICHES"} data={bagelSandwichesData} />
    </div>
  );
};

export default Sandwiches;
