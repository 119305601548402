import React from "react";
import "./home.css";

import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Home = () => {
  return (
    <section className="container">
      <div className="top__imgContainer">
        <LazyLoadImage effect="opacity" src="images/shop/shopFront.JPG" alt="Shop front" width="100%" height="100%"/>
      </div>
 
      <h3 className="container__divide centered ">SUPER DONUT</h3>

      <div className="container__col--flex">

        <div className="container--flex marginTopSm">
          <div className="centered container__col--flex padded">
            <div className="col__text container--flex centered width-eighty">
              <h2 className="container__col--flex">ABOUT</h2>
              <p className="container__col--flex">
                Super Donut is family-owned restaurant in Gilroy
                <br /> specializing in donuts and sandwiches for over 20 years.
              </p>
            </div>
          </div>

          <div className="container__col--flex">
            <LazyLoadImage effect="opacity" src="images/shop/donutFront.JPG" alt="Inside store" className="img--full"/>
          </div>
        </div>
      </div>

      <div className="marginTopSm">
        <div className="container--flex">

          <div className="centered container__col--flex about__menu padded">
            <div className="col__text container--flex centered width-eighty">
              <h2 className="container__col--flex">OUR MENU</h2>

                <NavLink className="about__menuBtn container__col--flex" to="/donuts">VIEW DONUTS</NavLink>
                <NavLink className="about__menuBtn container__col--flex" to="/sandwiches">VIEW SANDWICHES</NavLink>
                <NavLink className="about__menuBtn container__col--flex" to="/beverages">VIEW BEVERAGES</NavLink>

            </div>
          </div>

          <div className="container__col--flex">
            <LazyLoadImage effect="opacity" src="images/shop/donutMenu.JPG" alt="Menu" className="img--full"/>
          </div>
          
        </div>
      </div>

      <div className="padded marginTopSm">
        <div className="container--flex">
          <div className="container__col--flex centered padded bg-dom bg-contentBox container--flex home__trioImg">
            <p className="home__trioText ">Fresh Donuts</p>
            <LazyLoadImage effect="opacity"  src="images/donuts/donutHoles.JPG" alt="Donuts" className="home__trioImg" />
          </div>

          <div className="container__col--flex centered padded bg-dom bg-contentBox container--flex home__trioImg">
            <p className="home__trioText">Delicious Sandwiches</p>
            <LazyLoadImage effect="opacity"  src="images/sandwiches/sandwich9.JPG" alt="Sandwiches" className="home__trioImg" />
          </div>

          <div className="container__col--flex centered padded bg-dom bg-contentBox container--flex home__trioImg">
            <p className="home__trioText">Hot Coffee</p>
            <LazyLoadImage effect="opacity"  src="images/beverages/coffee.JPG" alt="Coffee" className="home__trioImg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
