import React from "react"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import MobileFooter from "./footer/MobileFooter";

class Layout extends React.Component {
  render(){
    return (
      <>
        <Header />
        <main>{this.props.children}</main>
        <Footer />
        <MobileFooter />
      </>
    )
  }
}export default Layout;