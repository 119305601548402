import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import ScrollToTop from './components/scrollToTop';

import Layout from './components/layout/Layout'
import Home from './pages/home/Home'
import Donuts from './pages/donuts/Donuts'
import Sandwiches from './pages/sandwiches/Sandwiches'
import Beverages from './pages/beverages/Beverages'
import Contact from './pages/contact/Contact'
import NotFound from './pages/notFound/NotFound'

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path='/Donuts' element={<Donuts/>}/>
          <Route exact path='/Sandwiches' element={<Sandwiches/>}/>
          <Route exact path='/Beverages' element={<Beverages/>}/>
          <Route exact path='/Contact' element={<Contact/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;