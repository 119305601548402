import React from "react";
import "./footer.css";
import { MdLocationPin } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import {FaCcVisa, FaCcMastercard, FaCcApplePay} from "react-icons/fa";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer__container container ">
        <div className="footer__col container__col--flex footer__contact">
          <h2>CONTACT</h2>
          <a
            href="https://www.google.com/maps/place/193+Welburn+Ave,+Gilroy,+CA+95020/@37.0198047,-121.5787446,17z/data=!3m1!4b1!4m5!3m4!1s0x8091e3d1823116b7:0x4bc8594401a27cf2!8m2!3d37.0198047!4d-121.5765559"
            target="_blank"
            rel="noreferrer"
          >
            <MdLocationPin />
            193 Welburn Ave, <br />
            Gilroy, CA 95020
          </a>
          <a href="tel:+14088480859" target="_blank" rel="noreferrer">
            <AiFillPhone />
            (408) 848-0859
          </a>
        </div>

        <div className="footer__col container__col--flex footer__hours">
          <h2>BUSINESS HOURS</h2>
          <p>Open Everyday <br />
          4:00 AM - 2:00 PM </p>
          <p>We Accept <FaCcVisa/> <FaCcMastercard/> <FaCcApplePay /> </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
